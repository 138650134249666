.body-container {
  /* background: url('/images/img-home.jpg') center center/cover no-repeat; */
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
  object-fit: contain;
  position: relative;
}

.body-container > h1 {
  color: #fff;
  font-size: 100px;
  margin-top: -100px;
}

#tsparticles {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  padding: 0;
  margin: 0;
  z-index: -1;
}

.body-container > p {
  margin-top: 8px;
  color: #fff;
  font-size: 32px;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
    'Lucida Sans', Arial, sans-serif;
}

.body-btns {
  margin-top: 32px;
}

.body-btns .btn {
  margin: 6px;
}

.typing-container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sentence {
  color: #fff;
  font-size: 32px;
  max-width: 60vw;
}

@keyframes blink {
  0% {opacity: 1;}
  40% {opacity: 1;}
  60% {opacity: 0;}
  100% {opacity: 0;}
}

.input-cursor { 
animation: blink .6s linear infinite alternate;
}

@media screen and (max-width: 960px) {
  .body-container > h1 {
    font-size: 70px;
    margin-top: -150px;
  }
}

@media screen and (max-width: 768px) {
  .body-container > h1 {
    font-size: 50px;
    margin-top: -100px;
  }

  .sentence {
    font-size: 20px;
  }

  .body-container > p {
    font-size: 30px;
  }

  .btn-mobile {
    display: block;
    text-decoration: none;
  }

  .btn {
    width: 100%;
  }
}
