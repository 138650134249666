.footer-container {
  background-color: #1a1919;
  padding: 2rem 0 2rem 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: auto;
}

.website-rights {
  color: #fff;
  margin-bottom: 16px;
}

/* Social Icons */
.social-icon-link {
  color: #fff;
  font-size: 24px;
}

.social-media-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 90%;
  max-width: 1000px;
  flex-direction: column;
}

.social-icons {
  display: flex;
  align-items: center;
  width: auto; /* Adjust width as needed based on the number of icons */
  color: #fff;
}

.fa-linkedin-square, .fa-github-square {
  color: #fff;
}

/* Responsive adjustments */
@media screen and (max-width: 820px) {
  .social-media-wrap {
    flex-direction: column;
  }
}